<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="LinkIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Geçmiş Başvurularım
            </h4>
          </div>
        </b-col>

        <!-- Profile Connections -->
        <b-col
          cols="12"
          class="mt-1"
        >
          <b-row class="text-center">
            <b-col cols="12">
              <!-- table -->
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
                theme="my-theme"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <template>
                        <b-button-group
                          class="mt-10"
                          size="sm"
                        >
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            size="sm"
                            class="mr-1 ml-2"
                            squared
                            variant="outline-primary"
                            @click="onApplicationInspectClicked"
                          >
                            <feather-icon
                              icon="SearchIcon"
                              class="mr-50"
                            />
                            <span>İncele</span>
                          </b-button>
                        </b-button-group>
                      </template>
                    </span>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        1 ila
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10','20','50','100']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pageLength: 15,
      columns: [
        {
          label: 'Firma Adı',
          field: 'firmName',
        },
        {
          label: 'Başvurulan Pozisyon',
          field: 'positionName',
        },
        {
          label: 'Notlar',
          field: 'notes',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [{ firmName: 'Firma Test', positionName: 'Deneme Pozisyonu', notes: 'Notlar buraya yazılacak' }],
      searchTerm: '',
    }
  },
  methods: {
    resetForm() {
    },
    onApplicationInspectClicked() {
      this.$router.push({ name: 'firma-kart', params: { firmId: 4 } })
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
