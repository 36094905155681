<template>
  <b-card>
    <!-- alert -->
    <b-col
      cols="12"
      class="mt-65"
    >
      <b-alert
        v-if="!isEmailValidated"
        show
        variant="warning"
        class="mb-50"
      >
        <h4 class="alert-heading">
          E-Posta adresiniz doğrulanmadı. E-Posta kutunuzu kontrol ediniz.
        </h4>
      </b-alert>
    </b-col>
    <!-- media -->
    <b-media
      no-body
      class="mt-5"
    >
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Yükle
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>JPG ya da PNG formatında, maksimum 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Ad(*)"
            label-for="account-firstName"
          >
            <b-form-input
              placeholder="Ad"
              name="name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Soyad(*)"
            label-for="account-surname"
          >
            <b-form-input
              name="surname"
              placeholder="Surname"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-Posta(*)"
            label-for="account-e-mail"
          >
            <b-form-input
              name="email"
              placeholder="E-Posta adresi"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Telefon"
            label-for="account-phone"
          >
            <b-form-input
              name="phone"
              placeholder="Telefon No"
            />
          </b-form-group>
        </b-col>

        <!--/ alert -->

        <b-col cols="8">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Değişiklikleri kaydet
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button
            v-if="!isEmailValidated"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-warning"
            type="reset"
            class="mt-2 ml-5"
            @click.prevent="resetForm"
            @click="onValidationMailSendClick"
          >
            Doğrulama E-Postası gönder
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      isEmailValidated: false,
    }
  },
  methods: {
    resetForm() {
      this.isEmailValidated = false
    },
    onValidationMailSendClick() {
      this.isEmailValidated = true
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
